@import '../variables.scss';

.toggle {
  :first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  :last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
