@import '../variables.scss';

.button {
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    opacity: 0.9;
    box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.25);
  }
}

.noShadow {
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
}
