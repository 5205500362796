@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Poppins';
}

.h-calc-100 {
  height: calc(100% - 100px);
}

.h-calc-150 {
  height: calc(100% - 150px);
}

.anchor-middle {
  text-anchor: middle;
}

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}

.react-datepicker-popper {
  z-index: 100;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: rgba(9, 92, 112, var(--tw-bg-opacity));
}

.react-datepicker__navigation--years-upcoming {
  background: url(assets/svgs/arrow.svg);
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.react-datepicker__navigation--years-previous {
  background: url(assets/svgs/arrow.svg);
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(180deg);
}

.react-datepicker__year-option:hover {
  background-color: rgb(9, 92, 112);
  color: white;
}

.react-datepicker__day--keyboard-selected {
  background-color: rgb(9, 92, 112);
}

.recharts-surface {
  .recharts-cartesian-axis:nth-of-type(3) {
    > line.recharts-cartesian-axis-line {
      display: contents;
    }
    > .recharts-cartesian-axis-ticks > .recharts-cartesian-axis-tick {
      > line {
        display: contents;
      }
    }
  }
}
